import React from 'react'

import Layout from '../components/Layout'
import SEO from "../components/seo"
import BookingForm from "../components/BookingForm"
import PageBanner from '../components/PageBanner'
import banner_image from '../images/page-banners/contact.jpg'
import ContactFrom from '../components/ContactForm'

import direct_call_icon from '../images/phone.svg'

const contactPage = () => {

  return (

    <Layout sidebarComponent={<BookingForm colWidth="col-md-12" />} className="contact-page">
      
      <SEO title="Contact us" />

      <PageBanner image={banner_image} title="Contact us"/>

      <div className="inner-content">
        <div className="row">
          <div className="col-lg-6">
            <div className="contact-form-wrapper">
              <p>
                If you wish to contact us you may kindly fill in the following form with your message. <br />
                We will be happy to assist you with your inquires.
              </p>
              <ContactFrom />
            </div>
          </div>
          <div className="col-lg-6">
            <iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3417.2867208716134!2d-4.014036484467553!3d31.073950576157472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd973d39cb8b0cc5%3A0x2b18b95ebfc84eb4!2sRiad+Chebbi!5e0!3m2!1sen!2sma!4v1565778443180!5m2!1sen!2sma" width="100%" height="270" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
            <div className="row">
              <div className="col-md-6">
                <div className="contact-address">
                  <p>
                    Tanamoust<br />
                    52202 Merzouga, Maroc
                  </p>
                  <p>
                    +212 659 757 391<br />
                    +212 535 578 333
                  </p>
                  <p>
                    <a href="mailto:info@riadchebbi.com" target="_blank" rel="noopener noreferrer">info@riadchebbi.com</a>
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <a className="direct-call" href="tel:212659757391">
                  <img src={direct_call_icon} /> <span>Direct Call</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


    </Layout>
    
  )
}

export default contactPage