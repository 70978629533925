import React from 'react'
import { Link } from 'gatsby'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'


import('./style.sass')

const contactSchema = Yup.object().shape({
  full_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  phone: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  message: Yup.string()
    .required('Required'),
})

const ContactFrom = () => (
  <div className="contact-form">
    
    <Formik
      initialValues={{
        full_name: '',
        phone: '',
        email: '',
        message: ''
      }}
      validationSchema={contactSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
                        
        var bodyFormData = new FormData()

        bodyFormData.set('full_name', values.full_name)
        bodyFormData.set('phone', values.phone)
        bodyFormData.set('email', values.email)
        bodyFormData.set('message', values.message)
        bodyFormData.set('subject', 'Contact')

        axios.post('https://contact.riadchebbi.com/contact.php', bodyFormData)
          .then((res) => {
            if(res.data.success) {
              alert('The form has been sent! We will contact as soon as possible.')
              resetForm()
            } else {
              alert(res.data.message)
            }
            setSubmitting(false)
          })
          
      }}
    >
      {({ isSubmitting }) => (
        <Form>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <Field type="text" name="full_name" id="full_name" className="form-control" placeholder="Name"/>
                <ErrorMessage name="full_name" component="div" className="error-msg" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Field type="text" name="phone" id="phone" className="form-control" placeholder="Phone"/>
                <ErrorMessage name="phone" component="div" className="error-msg" />        
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Field type="email" name="email" id="email" className="form-control" placeholder="E-mail address"/>
                <ErrorMessage name="email" component="div" className="error-msg" />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <Field component="textarea" name="message" id="message" className="form-control" placeholder="Message"/>
                <ErrorMessage name="message" component="div" className="error-msg" />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group terms_and_cond">
                <label><input type="checkbox" name="terms_conditions"id="terms_conditions" required/> Agree with <Link to="/terms-and-conditions">terms and condition</Link></label>
                <ErrorMessage name="terms_conditions" component="div" className="error-msg" />
              </div>
            </div>

          </div>
        
          <div>
            <button type="submit" disabled={isSubmitting} className="submit-btn">
              Contact us <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
          
        </Form>
      )}
    </Formik>

  </div>
)

export default ContactFrom